import React from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

function App() {
  return (
    <Parallax pages={5}>
        <ParallaxLayer factor={0.9} speed={0.5}>
        <header>
            <h1 class="primary" data-m="bounce-up">OpenPassVault</h1>
            <p class="tagline" data-m="scale" data-m-delay="1.25">The truly free, open source password manager.</p>
            <div id="buttons">
                <a class="primary-button" href="https://dash.openpassvault.com/login" target="_blank" rel="noreferrer"><button data-m="reveal-right" data-m-delay="2.25" data-m-duration="1">Login</button></a>
                <a class="primary-button" href="https://dash.openpassvault.com/register" target="_blank" rel="noreferrer"><button data-m="reveal-left" data-m-delay="2.25" data-m-duration="1">Sign up</button></a>
            </div>
        </header>
        </ParallaxLayer>
        <ParallaxLayer offset={0.92} factor={0.08} speed={1}>
            <p style={ { textAlign: "center" } } data-m="bounce-up" data-m-delay="3.5">↓ Scroll to find out more! ↓</p>
        </ParallaxLayer>
        <ParallaxLayer offset={1} factor={1} speed={1} sticky={{start: 1, end: 1.5}}>
            <div class="main">
                <h2 class="primary">Completely free, forever.</h2>
                <h3 class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>$0 lifetime subscription</h3>
                <p class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>You shouldn't have to pay to access basic features that help to keep your accounts secure.</p>
            </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2} factor={1} speed={1} sticky={{start: 2, end: 2.5}}>
            <div class="main">
                <h2 class="primary">Completely open source.</h2>
                <h3 class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>100% transparency</h3>
                <p class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>You shouldn't have to trust a mystery box with your data. You should be able to see exactly what's going on.</p>
            </div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} factor={0.8} speed={1} sticky={{start: 3, end: 3.3}}>
            <div class="main">
                <h2 class="primary">Completely secure.</h2>
                <h3 class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>Security is our priority</h3>
                <p class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>You shouldn't have to worry about your data being stolen. Keeping your data secure is our main priority.</p>
            </div>
        </ParallaxLayer>
        <ParallaxLayer offset={4.3} factor={1.2} speed={1}>
            <div id="footer">
                <h1 style={{textAlign: "center"}}>Don't compromise on security.</h1>
                <h2 class="tagline" style={{width: "60%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>Choose OpenPassVault.</h2>
                <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}><a href="https://dash.openpassvault.com"><button>Launch 🚀</button></a></div>
            </div>
        </ParallaxLayer>
    </Parallax>
  );
}

export default App;
